import $ from "jquery";
import http from "@/api/axios";
export default {
  name: "",
  components: {},
  data() {
    return {
      activeCase: false,
      type:0,
      caseList: [
        {
          subtitle:'智慧政务',
          mainBody:`<p style="text-indent:40px">步速者凭借多年在计算机应用软件技术领域的开发服务经验，面对政府客户的互联网时代的智慧政务的需求，现已为政府政务提供了完善的智慧政务的解决方案，包括门户网站、办公电子化、政务资源共享、在线监管系统、互联网+政务、公共资源交易平台等一体化智慧政务解决方案，使政府政务办公更加方便、透明、高效、公开、便民。</p?`
        },{
          subtitle:'智慧城市',
          mainBody: `<p style="text-indent:40px">以交通、校园、政务、园区等构成城市的核心领域为城市的辐射范围，步速者为这些核心领域提供了智慧交通、智慧校园、智慧政务、智慧园区等的信息化解决方案，共同助力、服务于智慧城市建设。</p>
          <p style="text-indent:40px">智慧交通将城市交通管理与服务作为整体系统来统筹规划和实施，智慧校园覆盖校园的校务管理、学生课程管理、教室管理等场景，智慧政务提供政务相关的办公电子化、政务资源共享、在线监管系统、互联网+政务、公共资源交易平台等，智慧园区提供园区工作流程智能化、资产管理数据化、运营信息化、服务平台化等。</p>
          `
        },{
          subtitle:'工业互联网',
          mainBody:`<p style="text-indent:40px">步速者利用已有的信息化成熟技术结合市场的智能网关等硬件技术，为传统工业提供工业故互联网平台化的解决方案。</p>`
        }
      ],
      solveList:[],
      bgList:{},
    };
  },
  methods: {
    openCase(index,item) {
      this.activeCase = index;
      item.mainBody = item.mainBody.replace(/\n/g,"<br/>");
      item.mainBody1 = item.mainBody1.replace(/\n/g,"<br/>");
      this.bgList = item;
      console.log(item);
    },
    alignCenter() {
      const w = document.body.clientWidth;
      const boxes = $(".cover-name")[0];
      if (boxes) {
        const boxWid = boxes.scrollWidth; //获取div的宽
        boxes.style.left = w / 2 - boxWid / 2 + "px";
      }
    },
    getSoluteData(type) {
      const kind = 3;
      if (type===undefined||type===null) {
          type=0;
      }else{
        document.getElementById("details").scrollIntoView();
      }
      this.type = type;
      http
        .get("/industrySolutions/findAllWeb", {
          params: {
            type,
            kind
          },
        })
        .then((res) => {
          this.solveList = res.content;
          this.openCase(0,res.content[0]);
        });
    },
  },
  mounted() {
    const _this = this;
    _this.alignCenter();
    let type = sessionStorage.getItem('type');
    _this.getSoluteData(type);
    window.onresize = function() {
      _this.alignCenter();
    };
  },
  beforeDestroy(){
    sessionStorage.removeItem('type');
  },
  computed: {},
  watch: {},
};